.chat-container {
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 90%, rgba(0,0,0,0));
}

.no-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.dividerWithText::before, .dividerWithText::after {
    content: "";
    flex: 1 1 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
